import React, { useState } from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import Step_0 from "./Step_0";
import Step_2 from "./Step_2";
import Step_1 from "./Step_1";
import { resetFormData, resetStep } from "../../store/slice/form/watersForm";

const AddEditWaters = ({ show, handleClose, editData }: any) => {
  const step = useSelector((state: any) => state?.waters?.step);
  

  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmClose = () => {
    dispatch(resetStep());
    dispatch(resetFormData());
    handleClose(false);
  };

  const handleCancelClose = () => {
    setShowConfirmation(false);
  };

  const handleCancelOpen = () => {
    setShowConfirmation(true);
  };

  return (
    <Box>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-md-700px"
        show={show}
        backdrop="static"
      >
        <div className="modal-header">
          <h2> {editData ? "Edit" : "Add"} Species</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => setShowConfirmation(true)}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <ul className="steps-list p-7">
          <li className="active">
            <button type="button" className="nav-link">
              <div className="step-icon">
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="#e6e7e8"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z"
                    fill="white"
                  />
                </svg>
              </div>
              Basic Info
            </button>
          </li>
          <li className={step === 1 || step === 2 ? "active" : ""}>
            <button type="button" className="nav-link">
              <div className="step-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="18"
                  fill="#fff"
                >
                  <path d="M20.5,14h-.5v3c0,.553-.447,1-1,1s-1-.447-1-1v-3h-2v3c0,.553-.447,1-1,1s-1-.447-1-1v-3h-2v3c0,.553-.448,1-1,1s-1-.447-1-1v-3h-3c-.552,0-1-.447-1-1s.448-1,1-1h3v-2h-3c-.552,0-1-.447-1-1s.448-1,1-1h3v-2h-3c-.552,0-1-.447-1-1s.448-1,1-1h3v-.5c0-1.933-1.567-3.5-3.5-3.5H3.5C1.567,0,0,1.567,0,3.5v15.5c0,2.761,2.239,5,5,5h15.5c1.933,0,3.5-1.567,3.5-3.5v-3c0-1.933-1.567-3.5-3.5-3.5Z" />
                </svg>
              </div>
              Weight And Height
            </button>
          </li>
          <li className={step === 2 ? "active" : ""}>
            <button type="button" className="nav-link">
              <div className="step-icon">
                <svg
                  xmlns="
http://www.w3.org/2000/svg"
                  id="Filled"
                  viewBox="0 0 24 24"
                  width="20"
                  fill="#ffffff"
                >
                  <path d="M12,24A12,12,0,1,0,0,12,12.013,12.013,0,0,0,12,24ZM12,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12,5Zm-1,5h1a2,2,0,0,1,2,2v6a1,1,0,0,1-2,0V12H11a1,1,0,0,1,0-2Z" />
                </svg>
              </div>
              Addition Info
            </button>
          </li>
        </ul>
        {step === 0 && <Step_0 handleClose={handleCancelOpen} />}
        {step === 1 && <Step_1 />}
        {step === 2 && <Step_2 handleClose={handleClose} editData={editData}/>}
      </Modal>
      <Modal
        show={showConfirmation}
        onHide={handleCancelClose}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <div className="modal-header">
          <h5 className="modal-title">Confirmation</h5>
          <button
            type="button"
            className="btn-close"
            onClick={handleCancelClose}
          ></button>
        </div>
        <div className="modal-body">
          <p>Closing this form will result in losing all unsaved data.</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancelClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleConfirmClose}
          >
            Yes, Close
          </button>
        </div>
      </Modal>
    </Box>
  );
};

export default AddEditWaters;
