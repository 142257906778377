import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { charValidate } from "../../components/utility";
import { uploadMultiPicture } from "../../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  setSpeciesFormData,
} from "../../store/slice/form/speciesForm";
import { APP_URLs } from "../../constants/appURLs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import './carausel.css';

const Step_0 = ({ handleClose, editData }: any) => {
  const formDatas: any = useSelector((state: any) => state?.species?.formData);
  const [imgUploading, setImgUploading] = useState<boolean>(false);

  let imgUrl = editData?.image
    ? editData?.image[0]
      ? editData?.image[0]
      : ""
    : "";

  const [speciesImg, setSpeciesImg] = useState(formDatas?.image ?? []);

  const [imgFileName, setImgFileName] = useState(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const Step_0_validation_schema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    scientificName: Yup.string().trim().required("Scientific Name is required"),
    description: Yup.string().trim().required("Name is required"),
    image: Yup.array().required("Image is required"),
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: formDatas?.name || "",
      scientificName: formDatas?.scientificName || "",
      image: formDatas?.image || [],
      description: formDatas?.description || "",
    },

    validationSchema: Step_0_validation_schema,
    onSubmit: async (values, { setFieldError }) => {
      if (!speciesImg && !formDatas?.image) {
        setFieldError("image", "Image is required");
        return;
      }
      dispatch(setSpeciesFormData(values));
      dispatch(nextStep());
    },
  });

  const handleChangeImageUpload = async (e: any) => {
    let files = e?.target?.files;
    if (files && files.length > 0) {
      let formData = new FormData();
      Array.from(files).forEach((file: any) => {
        formData.append("photo", file);
      });
      setImgUploading(true);
      try {
        let response = await uploadMultiPicture(formData);
        if (response?.status) {
          setSpeciesImg(response?.data?.images);
          formik.setFieldValue("image", response?.data?.images);
        } else {
          snackActions.error(
            response?.message ?? "Issue in image upload, try again later!"
          );
        }
      } catch (error) {
        snackActions.error("Error uploading images, try again later!");
      } finally {
        setImgUploading(false);
      }
    }
  };

  const removeImg = (index: any) => {
    const updatedImages = speciesImg.filter((_: any, i: any) => i !== index);
    formik.setFieldValue("image", updatedImages);
    setSpeciesImg(updatedImages);
    if (updatedImages.length === 0) {
      setImgFileName("");
    } else {
      setImgFileName(
        updatedImages?.map((img: any, i: any) => `Image ${i + 1}`).join(", ")
      );
    }
  };

  const ImageCarousel = ({ images, removeImg }: any) => {
    return (
      <Carousel
        showThumbs={true}
        autoPlay={false}
        interval={2000}
        showArrows={true}
        infiniteLoop={true}
      >
        {images.map((img: any, index: any) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              src={`${APP_URLs.awsS3ImageURL}${img}`}
              style={{
                maxWidth: "200px",
                margin: "0 auto",
                padding: "1rem",
                border: "1px solid #eeeeee",
              }}
              alt="Preview"
            />
            <CloseIcon
              className="close-icon"
              onClick={() => removeImg(index)}
              style={{
                position: "absolute",
                top: "-1px",
                left: '420px',
                right: 'auto',
                cursor: "pointer",
              }}
            />
          </div>
        ))}
      </Carousel>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Name<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Name"
              aria-label="Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name &&
              formik.errors.name &&
              typeof formik.errors.name === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              )}
          </Col>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Scientific Name<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Scientific Name"
              {...formik.getFieldProps("scientificName")}
            />
            {formik.touched.scientificName &&
              formik.errors.scientificName &&
              typeof formik.errors.scientificName === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.scientificName}
                  </div>
                </div>
              )}
          </Col>
          <Col sm={12} className="mb-4">
            <label className="form-label fw-bold">
              Enter Description<span className="red-asterisk">*</span>
            </label>
            <ReactQuill
              theme="snow"
              placeholder="Write something..."
              value={formik.values.description}
              modules={Step_0.modules}
              formats={Step_0.formats}
              onChange={(content) =>
                formik.setFieldValue("description", content)
              }
            />
            {formik.touched.description &&
              formik.errors.description &&
              typeof formik.errors.description === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.description}
                  </div>
                </div>
              )}
          </Col>
          <Col sm={12} className="mb-4">
            <label className="form-label fw-bold">
              Image upload<span className="red-asterisk">*</span>
            </label>
            <div>
              <label
                htmlFor="species-img"
                className="form-control form-control-lg form-control-solid cursor-pointer"
              >
                {imgFileName ? charValidate(imgFileName, 25) : "Choose File"}
              </label>
              <input
                id="species-img"
                type="file"
                multiple
                accept="image/*"
                className="form-control form-control-lg form-control-solid"
                placeholder="Upload Image"
                aria-label="Upload Image"
                onChange={handleChangeImageUpload}
                style={{
                  display: "none",
                }}
              />
              {formik.touched.image &&
                formik.errors.image &&
                typeof formik.errors.image === "string" && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.image}</div>
                  </div>
                )}
            </div>
          </Col>
        </Row>
        {imgUploading && (
          <div className="d-flex justify-content-center m-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {speciesImg && (
          <div
            style={{
              textAlign: "center",
              marginTop: "1rem",
              position: "relative",
            }}
          >
            {speciesImg?.length > 1 ? (
              <ImageCarousel
                images={speciesImg}
                removeImg={removeImg}
                autoPlay={true}
              />
            ) : (
              speciesImg?.map((img: any, index: any) => (
                <div
                  key={index}
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <img
                    src={`${APP_URLs.awsS3ImageURL}${img}`}
                    style={{
                      maxWidth: "200px",
                      margin: "0 auto",
                      padding: "1rem",
                      border: "1px solid #eeeeee",
                    }}
                    alt="Preview"
                  />
                  <CloseIcon
                    className="close-icon"
                    onClick={() => removeImg(index)}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={handleClose}
          size="lg"
          variant="secondary"
          disabled={imgUploading}
        >
          Close
        </Button>
        <Button
          className="ms-3"
          type="submit"
          size="lg"
          variant="primary"
          disabled={imgUploading}
        >
          Save & Next
        </Button>
      </div>
    </form>
  );
};

export default Step_0;

Step_0.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
Step_0.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
