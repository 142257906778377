import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { charValidate } from "../../components/utility";
import { uploadPicture } from "../../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { APP_URLs } from "../../constants/appURLs";
import { nextStep, setWaterFormData } from "../../store/slice/form/watersForm";

const Step_0 = ({ handleClose, editData }: any) => {
  const formDatas: any = useSelector(
    (state: any) => state?.waters?.waterFormData
  );
  const [imgUploading, setImgUploading] = useState<boolean>(false);

  let imgUrl = editData?.image
    ? editData?.image[0]
      ? editData?.image[0]
      : ""
    : "";

  const [watersImg, setWatersImg] = useState("");

  const [imgFileName, setImgFileName] = useState(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );

  const Step_0_validation_schema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    image: Yup.mixed().required("Image is required"),
    type: Yup.string().required("This field is required"),
  });

  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      name: formDatas?.name || "",
      image: formDatas?.image || "",
      type: formDatas?.type || "",
    },

    validationSchema: Step_0_validation_schema,
    onSubmit: async (values, { setFieldError }) => {
      if (!watersImg && !formDatas?.image) {
        setFieldError("image", "Image is required");
        return;
      }
      dispatch(setWaterFormData(values));
      dispatch(nextStep());
    },
  });

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      setImgUploading(true);
      let response = await uploadPicture(formData);
      if (response?.status) {
        setWatersImg(response?.data?.images);
        formik.setFieldValue("image", response?.data?.images);
        setImgUploading(false);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
        setImgUploading(false);
      }
    }
  };

  const removeImg = () => {
    formik.setFieldValue("image", "");
    formik.setFieldError("image", undefined);
    setWatersImg("");
    setImgFileName("");
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="p-7">
        <Row>
          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Enter Name<span className="red-asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Name"
              aria-label="Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name &&
              formik.errors.name &&
              typeof formik.errors.name === "string" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              )}
          </Col>

          <Col md={6} className="mb-4">
            <label className="form-label fw-bold">
              Select Waters type<span className="red-asterisk">*</span>
            </label>
            <select
              className="form-control form-control-lg form-control-solid"
              {...formik.getFieldProps("type")}
            >
              <option value="">Select</option>
              <option value="ocean">Ocean</option>
              <option value="glacier">Glacier</option>
              <option value="river">River</option>
              <option value="lake">Lake</option>
              <option value="pond">Pond</option>
            </select>
            {formik.touched.type && formik.errors.type && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.type}</div>
              </div>
            )}
          </Col>
          <Col sm={12} className="mb-4">
            <label className="form-label fw-bold">
              Image upload<span className="red-asterisk">*</span>
            </label>
            <div>
              <label
                htmlFor="company-img"
                className="form-control form-control-lg form-control-solid cursor-pointer"
              >
                {imgFileName ? charValidate(imgFileName, 25) : "Choose File"}
              </label>
              <input
                id="company-img"
                type="file"
                accept="image/*"
                className="form-control form-control-lg form-control-solid"
                placeholder="Upload Image"
                aria-label="Upload Image"
                onChange={handleChangeImageUpload}
                style={{
                  display: "none",
                }}
              />
              {formik.touched.image &&
                formik.errors.image &&
                typeof formik.errors.image === "string" && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.image}</div>
                  </div>
                )}
            </div>
            {imgUploading && (
              <div className="d-flex justify-content-center m-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {(watersImg || formDatas?.image) && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                  position: "relative",
                }}
              >
                <img
                  src={`${APP_URLs.awsS3ImageURL}${
                    watersImg || formDatas?.image
                  }`}
                  style={{
                    maxWidth: "200px",
                    margin: "0 auto",
                    padding: "1rem",
                    border: "1px solid #eeeeee",
                  }}
                  alt="Preview"
                />
                <CloseIcon className="close-icon" onClick={removeImg} />
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button onClick={handleClose} size="lg" variant="secondary" disabled={imgUploading}>
          Close
        </Button>
        <Button className="ms-3" type="submit" size="lg" variant="primary" disabled={imgUploading}>
          Save & Next
        </Button>
      </div>
    </form>
  );
};

export default Step_0;

Step_0.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
Step_0.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
