import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import { useDispatch } from 'react-redux'
import { setData } from '../../../../../store/slice/header/headerSlice'
import { ReactComponent as DashboardIcon } from "../../../../assets/svg/dashboard.svg"
import { ReactComponent as GeardIcon } from "../../../../assets/svg/gear.svg"
import { ReactComponent as SpeciesIcon } from "../../../../assets/svg/species.svg"
import { ReactComponent as WatersIcon } from "../../../../assets/svg/waters.svg"
import { ReactComponent as ReportedIcon } from "../../../../assets/svg/reported.svg"
import { ReactComponent as SettingsIcon } from "../../../../assets/svg/settings.svg"
import { ReactComponent as Baits } from "../../../../assets/svg/baits.svg"




type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config;
  
  const dispatch = useDispatch();
  
  const handleMenuClick = async () => {
    dispatch(setData(title));
  };

  return (
    <div className="menu-item">
      <Link
        className={clsx("menu-link without-sub", { active: isActive })}
        to={to}
        onClick={() => handleMenuClick()}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            {" "} {getIcons(title)}
            {/* <KTIcon iconName={icon} className="fs-1" /> */}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
}

function getIcons(iconTag: string): JSX.Element | null {
  if (iconTag === "Dashboard") {
    return (
      <DashboardIcon />
    );
  }
  else if (iconTag === "Gear") {
    return (
      <GeardIcon />
    );
   }
   else if (iconTag === "Bait") {
    return (
      <Baits />
    );
   }
   else if (iconTag === "Species") {
    return (
      <SpeciesIcon />
    );
   }
  else if (iconTag === "Waters") {
    return (
      <WatersIcon />
    );
   }
  else if (iconTag === "Reported Content") {
    return (
      <ReportedIcon />
    );
  }
  else if (iconTag === "Settings") {
    return (
      <SettingsIcon />
    );
  }
 
  return null; // Or handle other cases accordingly
}
 

export {SidebarMenuItem}
